var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _vm.editable && _vm.isWriting
        ? _c(
            "div",
            {
              staticClass:
                "alert alert-info alert-dismissible fade show cursor-pointer",
              staticStyle: {
                "margin-bottom": "13px !important",
                padding: "10px 3px !important",
              },
              attrs: { role: "alert" },
              on: { click: _vm.setPermitData },
            },
            [
              _c(
                "span",
                { staticClass: "q-pl-sm q-pr-md" },
                [
                  _c("q-icon", {
                    attrs: { name: "o_emoji_objects", size: "sm" },
                  }),
                ],
                1
              ),
              _c(
                "q-chip",
                { attrs: { dense: "", color: "teal", "text-color": "white" } },
                [
                  _vm._v(
                    " " +
                      _vm._s(_vm.$comm.getLangLabel("LBL0000242", "SWP")) +
                      " "
                  ),
                ]
              ),
              _vm._v(
                " " + _vm._s(_vm.$comm.getLangLabel("LBL0000269", "SWP")) + " "
              ),
            ],
            1
          )
        : _vm._e(),
      _c(
        "c-card",
        { attrs: { title: "LBL0000402" } },
        [
          _c("template", { slot: "card-detail" }, [
            _c(
              "div",
              { staticClass: "col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3" },
              [
                _c("c-field", {
                  attrs: {
                    disabled: !_vm.isWriting,
                    editable: _vm.editable,
                    data: _vm.workPermit,
                    deptValue: "assessWriteDeptCd",
                    type: "dept_user",
                    label: "LBL0000242",
                    name: "assessWriteUserId",
                  },
                  model: {
                    value: _vm.workPermit.assessWriteUserId,
                    callback: function ($$v) {
                      _vm.$set(_vm.workPermit, "assessWriteUserId", $$v)
                    },
                    expression: "workPermit.assessWriteUserId",
                  },
                }),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3" },
              [
                _c("c-field", {
                  attrs: {
                    disabled: !_vm.isWriting,
                    editable: _vm.editable,
                    data: _vm.workPermit,
                    deptValue: "assessApprovalDeptCd",
                    type: "dept_user",
                    label: "LBL0000243",
                    name: "assessApprovalUserId",
                  },
                  model: {
                    value: _vm.workPermit.assessApprovalUserId,
                    callback: function ($$v) {
                      _vm.$set(_vm.workPermit, "assessApprovalUserId", $$v)
                    },
                    expression: "workPermit.assessApprovalUserId",
                  },
                }),
              ],
              1
            ),
          ]),
        ],
        2
      ),
      _c(
        "c-table",
        {
          ref: "table",
          staticClass: "q-mt-sm",
          attrs: {
            title: "LBL0000298",
            columns: _vm.grid.columns,
            gridHeight: _vm.grid.height,
            data: _vm.workPermit.assessments,
            filtering: false,
            columnSetting: false,
            usePaging: false,
            editable: _vm.editable && _vm.isWriting,
            selection: "multiple",
            rowKey: "sopWorkSopAssessmentId",
          },
          on: { "table-data-change": _vm.tableDataChange },
        },
        [
          _c(
            "template",
            { slot: "table-button" },
            [
              _c(
                "q-btn-group",
                { attrs: { outline: "" } },
                [
                  _vm.editable && _vm.isWriting
                    ? _c("c-btn", {
                        attrs: {
                          label: "LBL0000253",
                          icon: "add",
                          showLoading: false,
                        },
                        on: { btnClicked: _vm.add },
                      })
                    : _vm._e(),
                  _vm.editable && _vm.isWriting
                    ? _c("c-btn", {
                        attrs: {
                          label: "LBL0000275",
                          icon: "remove",
                          showLoading: false,
                        },
                        on: { btnClicked: _vm.remove },
                      })
                    : _vm._e(),
                ],
                1
              ),
            ],
            1
          ),
        ],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }